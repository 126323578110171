import { Link } from 'gatsby';
import * as React from 'react';
import styled from '@emotion/styled';

import { Layout } from '../components/layout';
import { Rectangle } from '../components/rectangle';

import { useWindowSize } from '../hooks/useWindowSize';
import { ColorValues, getGradientColors, getGridRows } from '../utils';

const Content = styled.div`
  /* margin: 1rem; */
`;

const AnchorLink = styled.a`
  color: #222324;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Header = styled.h1`
  font-size: 5rem;
  background: linear-gradient(#469de7, #cb2b79);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;

  @media (max-width: 500px) {
    font-size: 3rem;
  }
`;

const Header2 = styled.h2`
  font-size: 2.8rem;
`;

const Header3 = styled.h3`
  font-size: 2.4rem;
  border-bottom: none;
  border-top: 1px solid #222324;
  margin: 0px 0px 2.5rem;
  padding: 2.5rem 0px 0px;
`;

export const StyledLink = styled(Link)`
  font-family: 'silkasemibold';
  font-size: 3.2rem;
  text-decoration: none;
  color: #222324;
  margin: 2rem 0 0;

  @media (max-width: 500px) {
    margin: 2rem;
    font-size: 2.4rem;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const Section = styled.section`
  margin: 2rem 0;
  display: grid;
  justify-items: flex-start;
  border-top: 1px solid #222324;
  padding-top: 4rem;

  @media (max-width: 500px) {
    margin: 4rem -2.5rem;
  }
`;

export const Grid = styled.div<{ stops: number }>`
  display: grid;
  grid-template-columns: ${({ stops }) => `repeat(${stops}, 1fr)`};
  grid-template-rows: ${({ stops }) => `repeat(${stops}, 1fr)`};
  gap: 0px 0px;
  grid-auto-flow: row;
  margin: 1rem 0;
`;

const CircleContainer = styled.div`
  display: grid;
  width: 440px;
  height: 440px;
  clip-path: circle(220px);

  @media (max-width: 500px) {
    width: 100vw;
    height: 220px;
    clip-path: circle(110px);
  }
`;

const VerticalContainer = styled.div`
  height: 400px;
  display: grid;
  grid-template-columns: repeat(20, 1fr);

  @media (max-width: 500px) {
    width: 100vw;
  }
`;

const HorizontalContainer = styled.div`
  width: 400px;
  display: grid;
  grid-template-rows: repeat(20, 1fr);

  @media (max-width: 500px) {
    width: 100vw;
  }
`;

const Paragraph = styled.p`
  margin: 0px 0px 2.5rem;
  padding: 0px;
  line-height: 1.5;
  font-size: 1.8rem;
`;

const Time = styled.div`
  font-family: 'silkasemibold';
  margin: 2rem 0;
  font-size: 1.8rem;
  padding: 2rem 0;
  border-top: 1px solid #222324;
  border-bottom: 1px solid #222324;
`;

export const colorValues: ColorValues = {
  topLeft: '469de7',
  topRight: 'cb2b79',
  bottomLeft: 'ff0',
  bottomRight: 'f0f',
};

const IndexPage = () => {
  const { width } = useWindowSize();
  const size = width && width <= 475 ? '20px' : '40px';
  const verticalWidth = width && width <= 475 ? `${width / 20}px` : '20px';
  const gridSize = width && width <= 475 ? `${width / 10}px` : '40px';
  const stops = 10;

  const colorsCircle = getGradientColors(
    { c1: colorValues.topLeft, c2: colorValues.topRight },
    11,
  );

  const colorsVertical = getGradientColors(
    { c1: colorValues.topRight, c2: colorValues.bottomLeft },
    20,
  );
  const colorsHorizontal = getGradientColors(
    { c1: colorValues.bottomRight, c2: colorValues.topLeft },
    20,
  );

  const gridRows = getGridRows(colorValues, 10);

  return (
    <Layout title="Gradients">
      <Content>
        <Header>Gradients!</Header>
        <Header2>A color exploration of gradients</Header2>
        <Paragraph>
          I suppose the genesis is a game called{' '}
          <AnchorLink href="https://apps.apple.com/us/app/i-love-hue/id1081075274">
            I Love Hue
          </AnchorLink>
          . You are presented with a grid of gradient colors which is then mixed
          up with the goal to re-assemble the gradient "puzzle".
        </Paragraph>
        <Header3>Process</Header3>
        <Paragraph>
          The first step was to find a way to get what I needed: each individual
          color between two colors of x length. Of course, there are a few{' '}
          <code>npm</code> packages and I'd settled on{' '}
          <AnchorLink href="https://www.npmjs.com/package/gradient-color">
            gradient-color
          </AnchorLink>
          . I'd also looked at{' '}
          <AnchorLink href="https://www.npmjs.com/package/javascript-color-gradient">
            javascript-color-gradient
          </AnchorLink>{' '}
          because, TypeScript, however ran into issues using it.
        </Paragraph>
        <Paragraph>
          <strong>Update:</strong> I'd found{' '}
          <AnchorLink href="https://www.npmjs.com/package/tinygradient">
            tinygradient
          </AnchorLink>{' '}
          and found it to be a better fit. It's sole dependency is{' '}
          <AnchorLink href="https://github.com/bgrins/TinyColor">
            TinyColor
          </AnchorLink>{' '}
          and is largely based on that API.
        </Paragraph>
        <Paragraph>
          Which is quite{' '}
          <AnchorLink href="https://github.com/bgrins/TinyColor/blob/master/README.md#accepted-string-input">
            permissive
          </AnchorLink>{' '}
          "which is meant to make typing a color as input as easy as possible."
        </Paragraph>
        <Paragraph>
          Essentially you create a 'tinygradient' and then call function to
          generate the desired number of colors in that gradient.
        </Paragraph>
        <pre>
          const gradient = tinygradient(['#fff', '#000']);
          <br />
          // RGB
          <br />
          const gradientColors = gradient.rgb(10);
        </pre>
        <Paragraph>
          What's nice here is that you have all the functions of{' '}
          <AnchorLink href="https://github.com/bgrins/TinyColor">
            TinyColor
          </AnchorLink>{' '}
          at your disposal. I especially liked that I could validate a string to
          see if it was a valid color and if not, provide a default. From there,
          you can loop through the array and call the function that suits your
          needs. In my case, I relied on <strong>toRgbString()</strong>.
        </Paragraph>
        <Paragraph>
          I started with simple horizontal lines which turned to a circle and
          then just began tweaking numbers to find interesting combinations.
          Ultimately I wanted to have a grid that would find all colors from the
          4 corners which was a fun process to figure out.
        </Paragraph>
        <Header3>Variations</Header3>
        <Paragraph>
          Once I had the process down, I wanted an easy way to change colors to
          play. Using the URL was an obvious choice which then led to also
          branching out to 'type' (circle, vertical, horizontal) as well as the
          number of 'stops' for the gradient.
        </Paragraph>
        <Paragraph>
          You can play with the URL structure (<code>/stops/hex/hex</code>) by
          clicking into each 'type'.
        </Paragraph>
        <Paragraph>Have fun!</Paragraph>
      </Content>
      <Section>
        <CircleContainer>
          {colorsCircle.map(c => (
            <Rectangle width="100%" height={size} color={c.toRgbString()} />
          ))}
        </CircleContainer>
        <StyledLink
          to={`/circle/11/${colorValues.topLeft}/${colorValues.topRight}`}
        >
          Circle
        </StyledLink>
      </Section>
      <Section>
        <VerticalContainer>
          {colorsVertical.map(c => (
            <Rectangle
              width={verticalWidth}
              height="100%"
              color={c.toRgbString()}
            />
          ))}
        </VerticalContainer>
        <StyledLink
          to={`/vertical/20/${colorValues.topRight}/${colorValues.bottomLeft}`}
        >
          Vertical
        </StyledLink>
      </Section>
      <Section>
        <HorizontalContainer>
          {colorsHorizontal.map(c => (
            <Rectangle width="100%" height="20px" color={c.toRgbString()} />
          ))}
        </HorizontalContainer>
        <StyledLink
          to={`/horizontal/20/${colorValues.bottomRight}/${colorValues.topLeft}`}
        >
          Horizontal
        </StyledLink>
      </Section>
      <Section>
        <Grid stops={stops}>
          {gridRows.map(colors =>
            colors.map(c => (
              <Rectangle
                width={gridSize}
                height={gridSize}
                color={c.toRgbString()}
              />
            )),
          )}
        </Grid>
        <StyledLink to="/grid">Grid</StyledLink>
      </Section>
      <Content>
        <Header3>Todo</Header3>
        <ol>
          <li>Add variables to the grid</li>
          <li>
            <s>
              Move to{' '}
              <AnchorLink href="https://www.npmjs.com/package/tinygradient">
                tinygradient
              </AnchorLink>
            </s>
          </li>
          <li>
            <s>
              Figure out TS issues with{' '}
              <AnchorLink href="https://www.npmjs.com/package/javascript-color-gradient">
                javascript-color-gradient
              </AnchorLink>
            </s>
          </li>
        </ol>
        <Time>February 17, 2021</Time>
      </Content>
    </Layout>
  );
};

export default IndexPage;
